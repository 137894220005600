<template lang="pug">
tr.loan-schedule-invoice-list-item
  td.text-nowrap {{ $t('common:formatDate', { value: invoice.paymentDate }) }}
  td.money {{ invoice.balance | money }}
  td.animated.fadeIn(v-if = 'editable && editing')
      fi-money-input.form-control-sm.form-control(
        :value = 'invoice.principal'
        @input = 'changePrincipal'
      )
  td.animated.fadeIn.money(v-else) {{ invoice.principal | money }}
  td.money {{ invoice.interest | money }}
  td.money.has-popover(:id = 'invoiceUid') {{ invoice.fee | money }}
  td.money {{ sum | money }}
  td.money {{ invoice.amountPaid | money }}
  td {{ $t('common:formatDate', { value: invoice.paidDate }) }}
  td: .badge(:class = '`badge-${loanInvoiceStatusColor(invoice.status)}`') {{ status.human }}
  b-popover(
    :target   = 'invoiceUid'
    triggers  = 'hover'
    placement = 'bottom'
  )
    .row.text-nowrap(v-for = '(value, key) in invoice.feeDetails')
      .col-6 {{ key }}
      .col-6 {{ value | money }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import { mapGetters } from 'vuex'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiDatepicker from '@/components/FiDatepicker'

export default {
  name: 'loan-schedule-invoice-list-item',

  components: { FiDatepicker, FiFormField, FiMoneyInput },

  props: {
    invoice: {
      type: Object,
      required: true
    },
    editable: Boolean,
    editing: Boolean
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    ...mapGetters('loans', ['loanInvoiceStatusColor']),
    invoiceUid () {
      return `invoice_${this._uid}`
    },
    sum () {
      const { principal, interest, fee } = this.invoice
      return principal + interest + fee
    },
    status () {
      return this.classifierById(this.invoice.status.classifier, this.invoice.status.id)
    }
  },
  methods: {
    changePrincipal (value) {
      this.$emit('input', {
        paymentDate: this.invoice.paymentDate,
        amount: value
      })
    },
    changeDeadlineDate (value) {
      this.$emit('input', {
        paymentDate: this.invoice.paymentDate,
        deadlineDate: value
      })
    }
  }
}
</script>


<style lang="scss" scoped>
.has-popover {
  cursor: pointer;
}
</style>
